export const environment = {
  production: true,
  apiConstants: {
    AZURE_B2C_AUTHORITY: 'https://jhhdxprod.b2clogin.com/tfp/jhhdxprod.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail',
    AZURE_B2C_CLIENT_ID: 'bd116b02-936c-4497-8708-600845fdd15a',
    AZURE_B2C_SCOPE: 'https://jhhdxprod.onmicrosoft.com/cfce05bf-4915-4301-864b-188a706ca444/user_impersonation',
    AZURE_B2C_RESETPASSWORD: 'https://jhhdxprod.b2clogin.com/tfp/jhhdxprod.onmicrosoft.com/B2C_1A_PASSWORDRESETEMAIL',
    GATEWAY_BASE_URL: 'https://gateway.prod-uaenorth.jawbonehealth.com/',
    USER_V2_API_URL: '/user/api/v0.2',
    HEALTHCLOUD_API_URL: '/healthcloud/api/v0.1',
    CUSTOMER_TENANT: '',
    DEMO: false,
  }
};
